import React from 'react'
import { Link } from 'gatsby'
import { Container } from 'components/common'
import NavbarLinks from '../NavbarLinks'
import { Wrapper, Thumbnail } from './styles'
import {
	logo
  } from 'data/config'

const Navbar = () => (
	<Wrapper as={Container}>
		<Link to="/">
			<Thumbnail>
				<img src={logo} alt="SparksDNA" />
			</Thumbnail>
		</Link>
		<NavbarLinks desktop />
	</Wrapper>
)

export default Navbar
