import React from 'react'
import { Container } from 'components/common'
import { Wrapper, Flex, Links, Details, PoweredBy, Item } from './styles'
import social from './social.json'

export const Footer = () => (
	<Wrapper>
		<Flex as={Container}>
			<Details>
				{/* <h2>Sparks DNA</h2> */}
				<Item as="a" href={'/'} rel="noopener noreferrer">Sparks DNA</Item>
				<div>
					We are living in {new Date().getFullYear()}
				</div>
				<PoweredBy>
					<span>
						Powered by Gatsby
					</span>
				</PoweredBy>
			</Details>
			<Links>
				{social.map(({ id, name, link, icon }) => (
					<a
						key={id}
						href={link}
						target="_blank"
						rel="noopener noreferrer"
						aria-label={`add me to your connection on ${name}`}
					>
						<img width="24" src={icon} alt={name} />
					</a>
				))}
			</Links>
		</Flex>
	</Wrapper>
)
